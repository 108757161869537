import React, { Component} from 'react';
import {Modal, Button, Label, ListGroupItem} from 'react-bootstrap';
import PropTypes from 'prop-types';
import PickList from "../components/PickList";
import "./Dialog.css";

class Dialog extends Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
  
      this.state = {
        show: this.props.show,
        label: this.props.label,
        text: this.props.text,
        userId: this.props.userId,
        class: this.props.class,
        picklist: this.props.picklist
      };
    }
  
    handleClose() {
      this.setState({ show: false });
    }
  
    handleShow() {
      this.setState({ show: true });
      //console.log(this.props.picklist);
    }
  
    render() {
      return (
        <div>
          <ListGroupItem className="dialog-nostyle" onClick={this.handleShow} >
            <Label className={this.state.class} disabled={true}>{this.state.label}</Label>
             {this.state.text}
          </ListGroupItem>
  
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Picks for {this.state.text}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
              <PickList picks={this.state.picklist} to={"#"}></PickList>
              </p>            
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }

  
Dialog.propTypes = {
    show: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    picklist: PropTypes.array.isRequired
}

Dialog.defaultProps = {
    show: false,
    picklist:[]
};
  
export default Dialog;

