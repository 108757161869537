 

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NFLHelper from "../components/NFLTeams";
import "./PickItem.css";

class PickItem extends Component {
  render() {
    return(
    <div className="indicator-item">
        <div className="indicator-item-icon">
         <div className={"teamlogo "+NFLHelper.TeamLogoXS(this.props.selectedWinner)}></div>
        </div>
        <div className="indicator-item-value"><span className="indicator-value-counter" data-toggle="counter" data-end="36">{'Week '+this.props.pickId.trim().split(';')[1]} <span class={"badge badge-warning "+(this.props.isRandom?"":"hidden")}>Random Pick</span></span>
            <div className="indicator-value-title">{  NFLHelper.TeamFullName(this.props.selectedWinner)} over {  NFLHelper.TeamFullName(this.props.selectedLoser)} </div>
        </div>
    </div>);
  }
}

PickItem.propTypes = {
  handleClick: PropTypes.func,
  selectedWinner: PropTypes.string.isRequired,
  selectedLoser: PropTypes.string.isRequired,
  pickId: PropTypes.string.isRequired,
  isRandom: PropTypes.bool.isRequired
}

PickItem.defaultProps = {
    disabled: false
};

export default PickItem;