import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewPick from "./containers/NewPick";
import Picks from "./containers/Picks";
import ResetPassword from "./containers/ResetPassword";
import Settings from "./containers/Settings";
import ChangePassword from "./containers/ChangePassword";
import Notes from "./containers/Notes";
import Standings from "./containers/Standings";
import Admin from "./containers/Admin";
import Help from "./containers/Help";
import Activity from "./containers/Activity";
import AdminSchedule from "./containers/AdminSchedule";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";


export default ({ childProps }) =>
  <Switch>
    
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps}/>
    
    <AuthenticatedRoute path="/help" exact component={Help} props={childProps}/>
    <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
    <AuthenticatedRoute path="/home" exact component={Home} props={childProps} />
    <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps} />
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps}/>
    <AuthenticatedRoute path="/admin" exact component={Admin} props={childProps} />
    <AuthenticatedRoute path="/activity" exact component={Activity} props={childProps} />
    <AuthenticatedRoute path="/adminschedule" exact component={AdminSchedule} props={childProps} />

    <AuthenticatedRoute path="/picks/new" exact component={NewPick} props={childProps} />
    <AuthenticatedRoute path="/admin/:id" exact component={Notes} props={childProps} />

    <AuthenticatedRoute path="/picks" exact component={Picks} props={childProps} />
    <AuthenticatedRoute path="/standings" exact component={Standings} props={childProps} />

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;




