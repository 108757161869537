import React, { Component } from "react";
import { ListGroup, ListGroupItem, Button   } from "react-bootstrap";
import "./Standings.css";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import RefreshButton from "../components/RefreshButton";
import NFLHelper from "../components/NFLTeams";
import WeekSelector from "../components/WeekSelector";
import Dialog from "../components/Dialog";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      userList: [],
      lockPicks: [],
      activeWeek: 0,
      weekTable: [],
      collapsed: false,
      isGameRefresh:false,
      allUserPicks: [] //all picks in raw format
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    //bind the week selector to this object
    this.handleSetActiveWeek = this.handleSetActiveWeek.bind(this);
    this.CollapseStandings = this.CollapseStandings.bind(this);
    this.RefreshStandings = this.RefreshStandings.bind(this);

    try {

      let userList = await this.users();
      //sort users by name, then strikes, then RANK
      userList.sort( function(a, b) {
        if (a.strikes === b.strikes) {
           if(a.strikes===3 && a.finalRank !==null && b.finalRank!==null){
             return a.finalRank > b.finalRank ? 1 : -1;
           }
           return ('' + a.firstName+a.lastName).localeCompare(b.firstName+b.lastName);
        }
        return a.strikes > b.strikes ? 1 : -1;
      });

      this.setState({ userList });

      const allUserPicks = await this.getLockedPicks();
      this.setState({allUserPicks});

      //get latest week
      let activeWeek=0;
      let weekTable=[];
      if(allUserPicks.length>0){
        allUserPicks.forEach((item)=>{
          if(weekTable.indexOf(item.week) === -1){
            weekTable.push(item.week);
          }
          if(item.week>activeWeek) activeWeek=item.week;
        });
        this.setState({weekTable});
        this.setActiveWeek(activeWeek);
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({ isLoading: false });
    this.props.loadStandings(false);
  }

  handleSetActiveWeek(event){
    console.log("SET WEEK:"+event);
    this.setState({activeWeek:event})
    this.setActiveWeek(event);
  }

  CollapseStandings(event){
    this.setState({collapsed:!this.state.collapsed})
  }

  //Refresh game stats
  async RefreshStandings(event){
    this.setState({isGameRefresh:true})
    await this.props.loadStandings(true);
    this.setState({isGameRefresh:false});

  }

  setActiveWeek(week){
    let activeWeek = week;
    this.setState({activeWeek:week});

    //bucket picks for activeWeek
    let gameList = [];
    this.state.allUserPicks.forEach((pick)=>
    {
      if(pick.week===activeWeek){
        let username = pick.userName + ((pick.isRandom)? " (*R)":"");

        if(gameList[pick.selectedWinner]==null) gameList[pick.selectedWinner]= {selectedWinner:pick.selectedWinner, strikes:pick.strikes, selectedLoser:pick.selectedLoser, gameTime: pick.gameTime, weekId:pick.pickId, users:[username]};
        else{
          gameList[pick.selectedWinner].users.push(username);
        }
      }
    });

    let lockPicks = [];
    Object.keys(gameList).forEach((key)=>{
      lockPicks.push({selectedWinner:gameList[key].selectedWinner,strikes:gameList[key].strikes, selectedLoser:gameList[key].selectedLoser, weekId:gameList[key].weekId, users:gameList[key].users});
    });

    //console.log(lockPicks);

    this.setState({ lockPicks:lockPicks });
  }

  users() {
    return this.props.gameObj.standings;
    //return API.get("3strikes", "/3strikes_users/"+config.game.GAME_ID);
  }

  getLockedPicks(){
    return this.props.gameObj.lockedpicks;
    // return API.get("3strikes", "/3strikes_lockedpicks/"+config.game.GAME_ID);
  }

  HandleUserStandingsView(item){
    console.log(item);

  }

  GenerateSingleUserPicks(userid){
    let templist = [];

    this.state.allUserPicks.forEach(item=>{
      if(item.userId===userid) templist.push(item);
    });

    return templist;
  }

  renderStandingsList(users) {
    return [{}].concat(users).map(
      (user, i) =>
        i !== 0
          ? <LinkContainer
              key={user.userGuid}
              to={`#`}
              className="dialog-parent-nostyle"
            >
              <ListGroupItem key={user.userId} onClick={() => { this.HandleUserStandingsView(user.userId) }}>
              <Dialog show={false}
                handleClose={this.renderStandings}
                class={"badge pull-right badge-strikes"+user.strikes+""}
                userId={user.userId}
                label={((user.finalRank === undefined)? user.strikes+" Strikes" : "#"+user.finalRank)}
                text={user.firstName +" "+user.lastName}
                picklist={this.GenerateSingleUserPicks(user.userId)} />

              </ListGroupItem>
            </LinkContainer>
          : <span key="2"></span>
    );
  }

  drawLockedUserList(users){
    return (users).map((user)=>
      <div className="user" key={user.userGuid}>{user}</div>
    )
  }


  renderLockedPicks(picks) {

    return [{}].concat(picks).map(
      (pick, i) =>
        i !== 0
            ? <ListGroupItem>
              <div className={((pick.strikes>0)?"isLoss":"")}>
                <div className="list-group-item-heading">
                  <div className={"teamlogo "+NFLHelper.TeamLogoXS(pick.selectedWinner)}></div>
                  <div className="list-group-item-heading-text">{NFLHelper.TeamFullName(pick.selectedWinner) +" over "+NFLHelper.TeamFullName(pick.selectedLoser)} <span className="pickUserCount">({pick.users.length})</span></div>
                </div>
                <div className={"pickTable "}>{this.drawLockedUserList(pick.users)}</div>
              </div>
              </ListGroupItem>
          : <span key="1"></span>
    );
  }

  renderStandings() {
    return (
      <div className="row">
        <Dialog show={false} handleClose={this.renderStandings} />
        <div className="col-md-4">
          <div className="users z-widget">
            <div className="z-widget-head">
            <div className="pull-right">
              <RefreshButton className="refresh-btn z-mini-btn" isLoading={this.state.isGameRefresh}  onClick={() => { this.RefreshStandings() }}>
              </RefreshButton>
              <Button className="collapse-btn z-mini-btn"  onClick={() => { this.CollapseStandings() }}>
              {!this.state.collapsed ?
                <i className="fas fa-angle-double-down"></i>
                : <i className="fas fa-angle-double-up"></i> }
              </Button>
            </div>
            <span className="title">Standings</span></div>
             <div className="z-widget-body">
              <div className="z-widget-loading"><LoadingSpinner isLoading={this.state.isLoading} /></div>
              {!this.state.collapsed ?
                 <ListGroup id="standingsBlock">
                 {!this.state.isLoading && this.renderStandingsList(this.state.userList)}
                </ListGroup>
              :""}
            </div>

          </div>
        </div>
        <div className="col-md-8">
          <div className="users z-widget z-lockedpicks-widget">
            <div className="z-widget-head">
                {this.state.lockPicks.length!==0?
                <span className="title">Locked Picks for Week {this.state.activeWeek}<WeekSelector handleClick={this.handleSetActiveWeek} weekList={this.state.weekTable} activeWeek={this.state.activeWeek}/></span>
                :
                    !this.state.isLoading?
                      <span className="title">No Locked Picks</span>:<span className="title">Loading Picks</span> }
                </div>
              <div className="z-widget-body">
              <div className="z-widget-loading"><LoadingSpinner isLoading={this.state.isLoading} /></div>
            <ListGroup className="lock-picks-list">
              {!this.state.isLoading && this.renderLockedPicks(this.state.lockPicks)}
            </ListGroup>
            </div>
          </div>

        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderStandings() : this.renderLander()}
      </div>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>3Strikes</h1>
        <p>You're not supposed to be here...</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }
}
