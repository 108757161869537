import React from "react";
import { Button } from "react-bootstrap";
import "./RefreshButton.css";

export default ({
  isLoading,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={`RefreshButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
   <i className={isLoading?"fas fa-sync spin":"fas fa-sync"}></i>
  </Button>;
