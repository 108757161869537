import React, { Component } from "react";
import { ListGroup, ListGroupItem, Button } from "react-bootstrap";
import "./Picks.css";
import { API } from "aws-amplify";

import LoadingSpinner from "../components/LoadingSpinner";
import NFLHelper from "../components/NFLTeams";
import RenderIf from "../components/RenderIf";
import config from "../config";
import TeamButton from "../components/TeamButton";


export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      schedule: [],
      picksMade: [],
      activeWeek: 0,
      activeYear: config.game.GAME_ID,
      pickIdList:[],
      pickAgainstIdList:[],
      pickIdListPostSeason:[],
      userName:""
    };
  }

  PostSeasonStartWeek = 19;

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      //bind
      this.handlePick = this.handlePick.bind(this);

      //get picks made to tdate
      const picksMade = await this.getPicksToDate();

      //build pick state data set
      let pickIdList=[], pickAgainstIdList=[], pickIdListPostSeason=[];
      picksMade.forEach((item,i)=>
      {
        if(i+1<this.PostSeasonStartWeek){
        pickIdList.push(item.selectedWinner);
        pickAgainstIdList[item.selectedLoser]==null? pickAgainstIdList[item.selectedLoser]=1:pickAgainstIdList[item.selectedLoser]+=1;
        }
        else{
          //console.log("Add "+item.selectedWinner+" to post season picks");
          pickIdListPostSeason.push(item.selectedWinner);
        }
      });

      this.setState({pickIdList, pickAgainstIdList, pickIdListPostSeason});
      console.log(pickIdListPostSeason);

      //get picks
      this.setState({ picksMade });
      const activeWeek =(picksMade.length>0)? (Number(picksMade[picksMade.length-1].pickId.split(";")[1])+1): 1;
      const activeYear = (picksMade.length>0)? (picksMade[picksMade.length-1].pickId.split(";")[0]): config.game.GAME_ID;
      this.setState({ activeWeek });
      this.setState({ activeYear });

      //picks for week where needed:
      const schedule = await this.getScheduleForWeek(activeYear+";"+activeWeek);
      this.setState({ schedule });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
    this.props.loadStandings(false);

  }

  //return true if the pick in question is NOT legal
  isNotValidPick(team, vs){
    if(this.state.activeWeek>=this.PostSeasonStartWeek){
      console.log("POST SEASON");
      if(this.isPicked(team)) return true;
      return false;
    }else{
      console.log("REGULAR SEASON: "+this.state.activeWeek);
      if(this.isPicked(team) || this.isPickedAgainstTwice(vs)) return true;
      return false;
    }
  }

  //has this team been picked before?
  isPicked(team){
    if(this.state.activeWeek>=this.PostSeasonStartWeek){
      if(this.state.pickIdListPostSeason.indexOf(team)>=0) return true;
      return false;
    }else{
      if(this.state.pickIdList.indexOf(team)>=0) return true;
      return false;
    }
  }

  //has this team been picked against twice?
  isPickedAgainstTwice(team){
    if(this.state.pickAgainstIdList[team]>=2) return true;
    return false;
  }

  //get all picks made to date
  getPicksToDate() {
    return API.get("3strikes", "/3strikes_picks",{
      'queryStringParameters': {
        'gameId': config.game.GAME_ID
      }
    });
  }

  //get schedule for given week
  getScheduleForWeek(week) {
    console.log("Getting Schedule: "+week);
    return API.get("3strikes", "/3strikes_schedule/"+week);
  } 

  deletePick(pickId){
    return API.del("3strikes", "/3strikes_picks/"+pickId);
  }

  createUser(userdata) {
    return API.post("3strikes", "/3strikes_users", {
      body: userdata
    });
  }

  savePick(pickdata) {
    return API.post("3strikes", "/3strikes_picks", {
      body: pickdata
    });
  }

  //new pick saved event.
 async handlePick(scheduleId, winnerId, loserId){
console.log(scheduleId);
console.log("winner: "+winnerId +" over "+loserId);

  this.setState({ isLoading: true });

    try{
      this.setState({userName:(this.props.givenName + " " + this.props.familyName)});
      }catch(e){
        console.log("Failed to create user:"+e);
    }

    try {
        //save pick
        let pickdata = (scheduleId).toString().trim().split(";");

        try{
          this.props.logActivity({
          gameId: config.game.GAME_ID,
          name: (this.props.gameObj.user.firstName + " " + this.props.gameObj.user.lastName),
          activity: "MadePick",
          description: "{week:"+this.state.activeWeek+",selectedWinner:"+winnerId+",selectedLoser:"+loserId+"}"
        })}catch(e){console.log(e);}

        await this.savePick({
            pickId: pickdata[0]+";"+pickdata[1], //year;week
            scheduleId: scheduleId,
            selectedWinner: winnerId,
            selectedLoser: loserId,
            userName: (this.props.gameObj.user.firstName + " " + this.props.gameObj.user.lastName),
            weekNum: this.state.activeWeek,
            isRandom: false
          });
         //reload picks?
        } catch (e) {
          console.log(e);
          alert(e);
          this.setState({ isLoading: false });
          return;
        }

        //done--reload
        this.componentDidMount();  // */
  }

  //delete a pick
  handlePickDelete = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      let pickdata = ([event.target.id]).toString().trim().split(";");

      await this.deletePick(pickdata[0]+";"+pickdata[1]);

      try{
        this.props.logActivity({
        gameId: config.game.GAME_ID,
        name: (this.props.gameObj.user.firstName + " " + this.props.gameObj.user.lastName),
        activity: "DeletePick",
        description: pickdata[0]+";"+pickdata[1]
      })}catch(e){console.log(e);}

    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
      return;
    }
    this.componentDidMount();
  }


  /*
removed date portion:

<div className="row">
                  <div className="col-xs-12 col-md-12 gameTime">
                  {new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  }).format(note.gameTime)}
                 </div>
                </div>
  */

 getCurrentTimePST(){
  return (new Date(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"})));
 }

 getCurrentEndOfDayPST(){
  let temp = this.getCurrentTimePST();
  temp = temp.setHours(23,59,59,0);
  //console.log(new Date(temp));
  return (new Date(temp)).getTime();

 }

 newDateBeginningOfDayPST(gameTime){
  let temp = (new Date(new Date(gameTime).toLocaleString("en-US", {timeZone: "America/Los_Angeles"})));
  temp = temp.setHours(0,0,0,0);
//  console.log(new Date(temp));
  return (new Date(temp)).getTime();
 }

  renderScheduleList(schedule) {
    return [{}].concat(schedule).map(
      (note, i) =>
        i !== 0
          ? <ListGroupItem
              key={note.scheduleId}
            >

              <div className="container-fluid text-center pickContainer">
              { (note.gameTime>this.getCurrentEndOfDayPST()) ?   <div className="row teamrow">

                  <TeamButton
                    scheduleId={note.scheduleId}
                    disabled={this.isNotValidPick(note.awayId, note.homeId)}
                    handleClick={this.handlePick}
                    winnerId={note.awayId}
                    loserId={note.homeId}
                    />
                <TeamButton
                    scheduleId={note.scheduleId}
                    disabled={this.isNotValidPick(note.homeId, note.awayId)}
                    handleClick={this.handlePick}
                    winnerId={note.homeId}
                    loserId={note.awayId}
                    homeBanner={true}
                    />

                </div>:<span></span>
              }
              </div>
          </ListGroupItem>
          : <span></span>
  );
  }



  renderPicksMadeList(picks) {
    return [{}].concat(picks).map(
      (note, i) =>
        i !== 0
          ? <ListGroupItem key={note.pickId} className="pick-row">
                {RenderIf((note.pickId===this.state.activeYear+";"+(this.state.activeWeek-1)) && this.newDateBeginningOfDayPST(note.gameTime) > (this.getCurrentTimePST()) ,
                    <Button className="badge pull-right" id={note.pickId} onClick={this.handlePickDelete}><i className="fas fa-trash-alt"></i> Delete</Button>
                )}
                {RenderIf(note.gameTime < (this.getCurrentEndOfDayPST()) ,
                <Button className="badge pull-right" id={note.pickId} disabled={true} alt="All picks lock at midnight prior to kickoff"><i className="fas fa-lock"></i> LOCKED</Button>
            )}
                 {''+note.pickId.trim().split(';')[1]}: &nbsp;
                  {NFLHelper.TeamFullName(note.selectedWinner)} over {NFLHelper.TeamName(note.selectedLoser)}
            </ListGroupItem>
          : <span></span>

    );
  }

  renderPicks() {
    return (
      <div className="row">
        <div className="col-md-5">
          <div className="PickList z-widget">
            <div className="z-widget-head">
                {!this.state.isLoading && this.state.activeWeek>1?
                <span className="title">Your Picks</span>
                : <span className="title">No Picks Yet</span> }
            </div>
            <div className="z-widget-body">
                <div className="z-widget-loading"><LoadingSpinner isLoading={this.state.isLoading} /></div>
                <ListGroup>
                {!this.state.isLoading && this.state.activeWeek>1 && this.renderPicksMadeList(this.state.picksMade)}
                </ListGroup>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="users z-widget schedule">
            <div className="z-widget-head">
            {!this.state.isLoading?
            <div>
            <span className="title">{(this.state.activeWeek)? "Schedule for Week "+this.state.activeWeek : "Make a Pick"}</span>
            <span></span></div>
            :""}
            </div>
            <div className="z-widget-body">
            <div className="z-widget-loading"><LoadingSpinner isLoading={this.state.isLoading} /></div>
            <ListGroup>
            {!this.state.isLoading && this.renderScheduleList(this.state.schedule)}
            </ListGroup>
            </div>
        </div>
      </div>

      </div>
    );
  }

  render() {
    return (
      <div className="Picks">
        { this.renderPicks()}
      </div>
    );
  }
}
