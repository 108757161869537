import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import "./App.css";
import { API, Auth } from "aws-amplify";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import config from "./config";
import 'react-perfect-scrollbar/dist/css/styles.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: (!navigator.onLine && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) ? true : false,
      isAuthenticating: true,
      isOfflineMode: (!navigator.onLine && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) ? true : false,
      givenName: "",
      familyName: "",
      email: "",
      strikes: 0,
      gameObj:[]
    };
  }

  async logActivity(){
    try{

    }catch(e){
      console.log("Logging Error:"+e); //silent error
    }
  }

  //reload the standings (if the token is old).  Used for refresh only.
  async loadStandings(force){
    try{
      let tenMinutesAgo = new Date((new Date()).getTime() - 10*60000);
      console.log("check Refresh");

      if(force || this.state.gameObj.refreshtime<tenMinutesAgo.getTime()){
        console.log("Requires Refresh");
        if(force) await this.loadCustomObjects();
        else this.loadCustomObjects();
      }
    }catch(e){
      console.log("Failed to Refresh Game: "+e.message);
    }
  }

  //Loda content at start of "app"
  async loadCustomObjects(){

    try {
      //get game
      let gameObj=null;
      try{
        gameObj = await this.loadGameObj(config.game.GAME_ID);
        this.setState({gameObj, givenName:gameObj.user.firstName, familyName:gameObj.user.lastName});
      }catch(e){
        console.log("FAILED TO OPEN GAME: "+e.message);
      }
      //create user if does not exist

      if(gameObj.user.statusCode===500){
        try{
          console.log("NEW USER");
          const user = await Auth.currentAuthenticatedUser();
          const { attributes } = user;
          console.log("READ USER: "+this.given_name);
          console.log(attributes.given_name);
          this.setState({givenName:attributes.given_name, familyName:attributes.family_name, email:attributes.email});

          await this.createUser({
            gameId:config.game.GAME_ID,
            firstName:this.state.givenName,
            lastName:this.state.familyName,
            email:this.state.email
          })


          //reload game
          let gameObj = await this.loadGameObj(config.game.GAME_ID);
          this.setState({gameObj});
        }catch(e){
          console.log("Find/register user: "+e.message);
        }
      }

      this.logActivityAPI({
        gameId: config.game.GAME_ID,
        name: gameObj.user.firstName+" "+gameObj.user.lastName,
        activity: "LoggedIn",
        description: "App.js"
      })

      //post process game object to built charts

      //exits per week data
      try{
      let weekExitList=[]; for(let i=0;i<23;i++){weekExitList[i]=0;}
      this.state.gameObj.standings.forEach(item=>{ weekExitList[item.exitWeek]++});

      //Picks per week data
      const reducer = (total, item) => item.strikes<3? total + 1 : total+0;
      this.state.gameObj.activePlayers = this.state.gameObj.standings.reduce(reducer,0);

      let gameChart = function (array) {
              var r = [];
              array.forEach(function (a) {
                  if (!this[a.week]) {
                      this[a.week] = { week: a.week, Strikes: 0, Picks: 0, Exits: 0 };
                      r.push(this[a.week]);
                  }
                 // console.log(a.strikes);
                  this[a.week].Picks++;
                  if(a.strikes!==undefined){ this[a.week].Strikes += Number(a.strikes);}
              }, Object.create(null));
              return r;
          }(this.state.gameObj.lockedpicks);

      for(let i=0;i<23;i++){
        if(gameChart[i]===undefined){
          gameChart[i]={week:i+1, Strikes:0, Picks: 0, Exits:0}
        }else{
          gameChart[i].Exits = (weekExitList[i]!==undefined)? weekExitList[i+1] : 0
        }
      }
           this.state.gameObj.gameChart=gameChart;
           this.setState({gameObj:this.state.gameObj});
           //console.log(gameChart);
     }catch(e){
      console.log("Failed to Load Game Chart Strikes Data: ");
      console.log(e.message);
     }


    }
    catch(e) {
      if (e !== 'No current user') {
        if(this.state.gameObj===undefined)
          alert("User Failure: "+e);
      }
    }
  }

  //Loads current session (via AWS Amplify to see if user is already logged in)
  async componentDidMount() {
    try {
      this.loadStandings = this.loadStandings.bind(this);

      //get user
      console.log(await Auth.currentSession());
      this.userHasAuthenticated(true);

      //load the game
      await this.loadCustomObjects();

    }catch(e) {
      if (this.state.isOfflineMode){
        console.log("OFFLINE MODE ACTIVATED");
        await this.loadCustomObjects();
      }else{
        if (e !== 'No current user') {
          console.log("Login Failed: "+e);
        }
      }
    }

    this.setState({ isAuthenticating: false });
  }

  async loadGameObj(gameId) {
    try{
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){ //local cache if localhost
      let tenMinutesAgo = new Date((new Date()).getTime() - 10*60000);

      const gameObj =  JSON.parse(await localStorage.getItem("gameObj"));
      console.log(gameObj);
      //console.log(tenMinutesAgo.getTime()+" vs "+gameObj.refreshtime);

      if (gameObj && gameObj.refreshtime > tenMinutesAgo.getTime()) {
        console.log('gameObj is cached');
        this.setState({ gameObj });
      } else {
        console.log('gameObj not cached');
        const gameObj = await API.get("3strikes", "/3strikes_games/"+gameId, {});
        if(gameObj){
          localStorage.setItem("gameObj", JSON.stringify(gameObj));
          return gameObj;
        }
        else{

          console.log("gameObj: not online!?");
          return this.state.gameObj; //NOT ONLINE
        }
      }
    }
  }catch(e){
    console.log("Loading GameObj Failure: "+e.message);
  }

    //if not localhost: just run
    return API.get("3strikes", "/3strikes_games/"+gameId, {});
  }

  logActivityAPI(data) {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") return; //skip localhost
    return API.post("3strikes", "/3strikes_logs", { body: data});
  }

  createUser(userdata) {
    return API.post("3strikes", "/3strikes_users", {
      body: userdata
    });
  }

  loadFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : config.social.FB,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.1'
      });
    };

    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
 }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  userLoginLoad = item =>{
    this.loadCustomObjects(true);
  }

  logActivity = log =>{
    this.logActivityAPI(log);
  }

  handleLogout = async event => {
    await Auth.signOut();  //Remove Amplify cache of token
    this.userHasAuthenticated(false); //show logged out
    this.props.history.push("/login"); //redirect to login page
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userLoginLoad: this.userLoginLoad,
      logActivity: this.logActivity,
      loadStandings: this.loadStandings,
      givenName: this.state.givenName,
      familyName: this.state.familyName,
      email: this.state.email,
      gameObj:this.state.gameObj
    };

    return (
      !this.state.isAuthenticating?
      <div className="App">
        <Navbar className="z-header" >
          <div className="flex">

            <div className="flex-grow">
            <Navbar.Brand>
              <img src={"/footballOnBlack.png"} alt="3Strikes" />
              <Navbar.Link href="/Home"> 3Strikes</Navbar.Link>
            </Navbar.Brand>
            </div>
            <div className="z-header-right">
            {this.state.isAuthenticated
                ? <Fragment>
                  <div className="flex">
                  <Nav>
                    <LinkContainer to="/help">
                      <NavItem className="icon nav-item"><i className="fas fa-question-circle icon"></i><span className="z-header-link hidden-xs"> Help</span></NavItem>
                    </LinkContainer>
                    </Nav>
                  <Nav>
                    <LinkContainer to="/settings">
                      <NavItem className="icon s7-settings nav-item"><i className="fas fa-user-cog icon"></i><span className="z-header-link hidden-xs"> Settings</span></NavItem>
                    </LinkContainer>
                    </Nav>

                    <Nav>
                    <NavItem onClick={this.handleLogout}><i className="fas fa-sign-out-alt icon"></i><span className="z-header-link hidden-xs"> Logout</span></NavItem>
                    </Nav>
                  </div>
                  </Fragment>
                :
                  <Fragment>
                  <div className="flex">
                  <Nav>
                    <LinkContainer to="/signup">
                      <NavItem className="icon s7-settings nav-item"><i className="fas fa-user-plus icon"></i><span class="z-header-link hidden-xs"> Signup</span></NavItem>
                    </LinkContainer>
                    </Nav>
                    <Nav>
                    <LinkContainer to="/login">
                      <NavItem className="icon s7-settings nav-item"><i className="fas fa-sign-in-alt icon"></i><span class="z-header-link hidden-xs"> Login</span></NavItem>
                    </LinkContainer>
                    </Nav>
                    </div>
                  </Fragment>
              }

            </div>
            </div>
        </Navbar>

        <Navbar fluid collapseOnSelect expand="lg" className="navbar navbar-expand-lg z-sub-header">
        <Nav className="z-mobile-toggle" id="z-mobile-toggle">
          <Navbar.Toggle />
        </Nav>
        <Navbar.Collapse>
              {this.state.isAuthenticated?
              <Fragment>
                <div className="container">
               <Nav>
                <LinkContainer to="/Home"><NavItem className="nav-link">
                  <i className="icon fas fa-home"></i><span>Home</span>
                </NavItem></LinkContainer>
                <LinkContainer to="/Standings"><NavItem className="nav-link">
                  <i className="icon fas fa-trophy"></i> <span>Standings</span>
                </NavItem></LinkContainer>
                <LinkContainer to="/Picks"><NavItem className="nav-link">
                  <i className="icon fas fa-vote-yea"></i> <span>Make Picks</span>
                </NavItem></LinkContainer>
                {this.state.gameObj.user!==undefined && this.state.gameObj.user.isAdmin?
                <LinkContainer to="/Admin"><NavItem className="nav-link">
                <i className="icon fas fa-users-cog"></i> <span>Admin</span>
              </NavItem></LinkContainer>:<Fragment></Fragment>
                }
                {this.state.gameObj.user!==undefined && this.state.gameObj.user.isAdmin?
                <LinkContainer to="/Activity"><NavItem className="nav-link">
                <i className="icon fas fa-users-cog"></i> <span>Activity</span>
              </NavItem></LinkContainer>:<Fragment></Fragment>
                }
                {this.state.gameObj.user!==undefined && this.state.gameObj.user.isAdmin?
                <LinkContainer to="/AdminSchedule"><NavItem className="nav-link">
                <i className="icon fas fa-users-cog"></i> <span>Full Schedule</span>
              </NavItem></LinkContainer>:<Fragment></Fragment>
                }
               </Nav>
               </div>
              </Fragment>
              :
              <Fragment>
                  <div className="container">
                  <Nav>
                    <LinkContainer to="/signup"><NavItem className="nav-link">
                      <i className="fas fa-user-plus icon"></i><span>Signup</span>
                    </NavItem></LinkContainer>
                    <LinkContainer to="/login"><NavItem className="nav-link">
                      <i className="fas fa-sign-in-alt icon"></i><span>Login</span>
                    </NavItem></LinkContainer>
                  </Nav>
                  </div>
                  </Fragment>
              }
          </Navbar.Collapse>
        </Navbar>
        <div className="container">
        <Routes childProps={childProps} />
        </div>
      </div>
      :
       <div>
          <div id="loadingApp" className="loading-centered">
            <div><img width="100" height="100" src="/logo_loading.gif" alt="Loading..." /></div>
            <div className="loadingText">
           LOADING...
          </div>
          </div>
       </div>
    );
  }


}

export default withRouter(App);
