///
//
//  NFLHelper:  Provides static functions to pull information about each team
//
///

const teamsList = {
    'ARI':'Arizona',
    'ATL':'Atlanta',
    'BAL':'Baltimore',
    'BUF':'Buffalo',
    'CAR':'Carolina',
    'CHI':'Chicago',
    'CIN':'Cincinnati',
    'CLE':'Cleveland',
    'DAL':'Dallas',
    'DEN':'Denver',
    'DET':'Detroit',
    'GB' :'Green Bay',
    'HOU':'Houston',
    'IND':'Indianapolis',
    'JAC':'Jacksonville',
    'KC' :'Kansas City',
    'LAC':'L.A. Chargers',
    'LAR':'L.A. Rams',
    'MIA':'Miami',
    'MIN':'Minnesota',
    'NYG':'N.Y. Giants',
    'NYJ':'N.Y. Jets',
    'NE' :'New England',
    'NO' :'New Orleans',
    'LV':'Las Vegas',
    'PHI':'Philadelphia',
    'PIT':'Pittsburgh',
    'SF' :'San Francisco',
    'SEA':'Seattle',
    'TB' :'Tampa Bay',
    'TEN':'Tennessee',
    'WSH':'Washington'
  };

  const nicknameList = {
    'ARI':'Cardinals',
    'ATL':'Falcons',
    'BAL':'Ravens',
    'BUF':'Bills',
    'CAR':'Panthers',
    'CHI':'Bears',
    'CIN':'Bengals',
    'CLE':'Browns',
    'DAL':'Cowboys',
    'DEN':'Broncos',
    'DET':'Lions',
    'GB':'Packers',
    'HOU':'Texans',
    'IND':'Colts',
    'JAC':'Jaquars',
    'KC':'Chiefs',
    'LAC':'Chargers',
    'LAR':'Rams',
    'MIA':'Dolphins',
    'MIN':'Vikings',
    'NYG':'Giants',
    'NYJ':'Jets',
    'NE':'Patriots',
    'NO':'Saints',
    'LV':'Raiders',
    'PHI':'Eagles',
    'PIT':'Steelers',
    'SF':'49ers',
    'SEA':'Seahawks',
    'TB':'Buccaneers',
    'TEN':'Titans',
    'WSH':'Football Team'
  };

//Class name for the team logo (SMALL)
  const imageList_XS = {
    'ARI':'TeamLogo_xs_ari',
    'ATL':'TeamLogo_xs_atl',
    'BAL':'TeamLogo_xs_bal',
    'BUF':'TeamLogo_xs_buf',
    'CAR':'TeamLogo_xs_car',
    'CHI':'TeamLogo_xs_chi',
    'CIN':'TeamLogo_xs_cin',
    'CLE':'TeamLogo_xs_cle',
    'DAL':'TeamLogo_xs_dal',
    'DEN':'TeamLogo_xs_den',
    'DET':'TeamLogo_xs_det',
    'GB' :'TeamLogo_xs_gb',
    'HOU':'TeamLogo_xs_hou',
    'IND':'TeamLogo_xs_ind',
    'JAC':'TeamLogo_xs_jac',
    'KC' :'TeamLogo_xs_kc',
    'LAC':'TeamLogo_xs_lac',
    'LAR':'TeamLogo_xs_lar',
    'MIA':'TeamLogo_xs_mia',
    'MIN':'TeamLogo_xs_min',
    'NYG':'TeamLogo_xs_nyg',
    'NYJ':'TeamLogo_xs_nyj',
    'NE' :'TeamLogo_xs_ne',
    'NO' :'TeamLogo_xs_no',
    'LV':'TeamLogo_xs_lv',
    'PHI':'TeamLogo_xs_phi',
    'PIT':'TeamLogo_xs_pit',
    'SF' :'TeamLogo_xs_sf',
    'SEA':'TeamLogo_xs_sea',
    'TB' :'TeamLogo_xs_tb',
    'TEN':'TeamLogo_xs_ten',
    'WSH':'TeamLogo_xs_was'
  }

  const imageList_SM = {
    'ARI':'TeamLogo_sm_ari',
    'ATL':'TeamLogo_sm_atl',
    'BAL':'TeamLogo_sm_bal',
    'BUF':'TeamLogo_sm_buf',
    'CAR':'TeamLogo_sm_car',
    'CHI':'TeamLogo_sm_chi',
    'CIN':'TeamLogo_sm_cin',
    'CLE':'TeamLogo_sm_cle',
    'DAL':'TeamLogo_sm_dal',
    'DEN':'TeamLogo_sm_den',
    'DET':'TeamLogo_sm_det',
    'GB' :'TeamLogo_sm_gb',
    'HOU':'TeamLogo_sm_hou',
    'IND':'TeamLogo_sm_ind',
    'JAC':'TeamLogo_sm_jac',
    'KC' :'TeamLogo_sm_kc',
    'LAC':'TeamLogo_sm_lac',
    'LAR':'TeamLogo_sm_lar',
    'MIA':'TeamLogo_sm_mia',
    'MIN':'TeamLogo_sm_min',
    'NYG':'TeamLogo_sm_nyg',
    'NYJ':'TeamLogo_sm_nyj',
    'NE' :'TeamLogo_sm_ne',
    'NO' :'TeamLogo_sm_no',
    'LV':'TeamLogo_sm_lv',
    'PHI':'TeamLogo_sm_phi',
    'PIT':'TeamLogo_sm_pit',
    'SF' :'TeamLogo_sm_sf',
    'SEA':'TeamLogo_sm_sea',
    'TB' :'TeamLogo_sm_tb',
    'TEN':'TeamLogo_sm_ten',
    'WSH':'TeamLogo_sm_was'
  }

//Class name for the team logo (LARGE)
const imageList_LG = {
  'ARI': 'TeamLogo_ari',
  'ATL': 'TeamLogo_atl',
  'BAL':'TeamLogo_bal',
  'BUF':'TeamLogo_buf',
  'CAR':'TeamLogo_car',
  'CHI':'TeamLogo_chi',
  'CIN':'TeamLogo_cin',
  'CLE':'TeamLogo_cle',
  'DAL':'TeamLogo_dal',
  'DEN':'TeamLogo_den',
  'DET':'TeamLogo_det',
  'GB' :'TeamLogo_gb',
  'HOU':'TeamLogo_hou',
  'IND':'TeamLogo_ind',
  'JAC':'TeamLogo_jac',
  'KC' :'TeamLogo_kc',
  'LAC':'TeamLogo_lac',
  'LAR':'TeamLogo_lar',
  'MIA':'TeamLogo_mia',
  'MIN':'TeamLogo_min',
  'NYG':'TeamLogo_nyg',
  'NYJ':'TeamLogo_nyj',
  'NE' :'TeamLogo_ne',
  'NO' :'TeamLogo_no',
  'LV':'TeamLogo_lv',
  'PHI':'TeamLogo_phi',
  'PIT':'TeamLogo_pit',
  'SF' :'TeamLogo_sf',
  'SEA':'TeamLogo_sea',
  'TB' :'TeamLogo_tb',
  'TEN':'TeamLogo_ten',
  'WSH':'TeamLogo_was'
}

class NFLHelper {
    //constructor() { }

    //give a short name, get the full name
    static TeamName(shortname){
        return teamsList[shortname];
    }

    //returns Nickname
    static TeamNickname(shortname){
        return nicknameList[shortname]
    }

    //returns Name + City
    static TeamFullName(shortname){
        let city = "";
        if(shortname==='LAC' || shortname==='LAR'){
            city = "Los Angeles";
        }
        else if(shortname==='NYG' || shortname==='NYJ'){
            city= "New York";
        }
        else{
            city = teamsList[shortname];
        }
        return city+" "+nicknameList[shortname];
    }

    //returns the class identifier that shows this logo
    static TeamLogoSM(shortname){
       return imageList_SM[shortname]
    }

    //returns the class identifier that shows this logo
    static TeamLogoXS(shortname){
      return imageList_XS[shortname]
   }

    //returns the class identifier that shows this logo
    static TeamLogoLRG(shortname){
       return imageList_LG[shortname]
    }

}

export default NFLHelper;