import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Settings.css";
import { Auth } from "aws-amplify";
import {
    FormGroup,
    FormControl,
    ControlLabel
  } from "react-bootstrap";
import { isNullOrUndefined } from "util";
import config from "../config";
import AlertDismissible from "../components/AlertDismissible";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      given_name: "John",
      family_name: "Doe",
      user: null,
      alert:false,
      alertText:""
    };
  }


  //validate user form data
  validateForm() {
    return (
        this.state.given_name.length > 0 && 
        this.state.family_name.length>0
        );
  }

  //load data from AWS
  async componentDidMount() {
    try {
        const user = await Auth.currentAuthenticatedUser();
        this.setState({user});

        const { attributes } = user;
               
        const given_name=(isNullOrUndefined(attributes.given_name))? '': attributes.given_name;
        const family_name=(isNullOrUndefined(attributes.family_name))? '': attributes.family_name;

       this.setState({given_name}); 
       this.setState({family_name});

        console.log(user);
        console.log(this.props.givenName);
      }
      catch(e) {
        if (e !== 'No current user') {
          alert(e);
        }
      }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

 
  handleSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });

    try{
      this.props.logActivity({
      gameId: config.game.GAME_ID,
      name: (this.props.givenName + " " + this.props.familyName),
      activity: "Changing Name",
      description: "Now known as: "+this.state.given_name+" "+this.state.family_name
    })}catch(e){console.log(e);}
  
    try {
        let result = await Auth.updateUserAttributes(this.state.user, {
            'given_name': this.state.given_name,
            'family_name': this.state.family_name
        });
        this.setState({ isLoading: false });

        console.log(result); // SUCCESS
        console.log(this.state.user);

       

        this.setState({alert:true,alertText:"Changing your name requires you log out and log back in."});

       // this.props.history.push("/"); //return
    } catch (e) {
        alert(e.message);
        this.setState({ isLoading: false });
    }
  
    
  }

  renderForm() {
    return (
      <div>
        <div className="row">
        <div>{this.state.alert && <AlertDismissible message={this.state.alertText}/>}</div>
        </div>
        <div className="Settings">
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="given_name" bsSize="large">
            <ControlLabel>First Name</ControlLabel>
            <FormControl 
                type="given_name"
                value={this.state.given_name}
                onChange={this.handleChange}
            />
            </FormGroup>
            <FormGroup controlId="family_name" bsSize="large">
            <ControlLabel>Last Name</ControlLabel>
            <FormControl
                type="family_name"
                value={this.state.family_name}
                onChange={this.handleChange}
            />
            </FormGroup>
            <LoaderButton
                block
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Update Profile"
                loadingText="Updating Profile…"
                />
        </form>
        <hr />
        <LinkContainer to="/settings/password">
          <LoaderButton
            block
            bsSize="large"
            text="Change Password"
          />
        </LinkContainer>
      </div></div>
    );
  }

  render() {
    return (
      <div className="row">
        {
          this.renderForm() }
      </div>
    );
  }
}
