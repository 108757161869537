import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./TeamButton.css";
import { Button } from "react-bootstrap";
import NFLHelper from "../components/NFLTeams";


class TeamButton extends Component {
  render() {
    return(
   
      <Button 
        className={this.props.homeBanner?"homeBanner team-button teamStyle_"+this.props.winnerId:" team-button teamStyle_"+this.props.winnerId} 
        id={this.props.scheduleId} 
        disabled={this.props.disabled} 
        onClick={() => this.props.handleClick(this.props.scheduleId,this.props.winnerId, this.props.loserId)} 
        value={this.props.pickId}>
            <div className={"teamLogo team_svg_"+this.props.winnerId.toLowerCase()}></div>
            <div className={"teamName"}>
            {NFLHelper.TeamName(this.props.winnerId)}
         <br />{NFLHelper.TeamNickname(this.props.winnerId)}
         </div>
      
      </Button>
    );
  }
}

TeamButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  scheduleId: PropTypes.string.isRequired,
  winnerId: PropTypes.string.isRequired,
  loserId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  homeBanner: PropTypes.bool
}

TeamButton.defaultProps = {
    disabled: false,
    homeBanner: false
};

export default TeamButton;