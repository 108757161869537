import React, { Component } from 'react';

class DismissibleAlert extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isActive: true,
    }
  }

  hideAlert() {
    this.setState({
      isActive: false,
    });
  }

  render() {
    if (this.state.isActive) {
      return (
          <div
            className="alert alert-warning z-alert"// alert-dismissible"
            role="alert" 
            dangerouslySetInnerHTML={{__html: this.props.message || "Empty Message"}}
          > 
            
          </div>
      );
    }
    return <div/>
  }
}

export default DismissibleAlert;

/*

  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => {this.hideAlert(); this.isActive=false}}>
              <span aria-hidden="true">&times;</span>
            </button>

            */