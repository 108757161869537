import React, { Component } from "react";
import {  PageHeader } from "react-bootstrap";
import "./Help.css";
import HelpBlock from "../components/HelpBlock";

export default class Help extends Component {
  constructor(props) {
    super(props);

    this.state = {
        
        };
  }

  render() {
    const regRules={
      title:"Regular Season Rules",
      preText:"Pick your weekly winner!",
      rules:[
          "You must pick one team that will win its football game each week.  No spreads.   Ties lose.",
          "Once you pick a team to win, you CANNOT pick that team to win again for the rest of the season.",
          "You cannot pick AGAINST a team more than TWO times.",
          "If the team you choose does not win its game, you get a strike.  A tie will count as a strike. ",
          "Three strikes, and you're out.",
          "The last person left in the pool wins first place. Second and third place is won by the next closest entrants."
      ]
    }
    const postSeasonRules={
      title:"Post-Season Rules",
      preText:"If there is more than one person left in the pool when the NFL regular season ends, the football pool continues into the NFL playoffs",
      rules:[        
          "Each entrant's \"strikes\" go with him.",
          "The slate of \"teams picked\" is wiped clean. Every team becomes eligible again.",
          "Each contestant in the playoffs must select one team per weekend that will win its football game. ",
          "A team may not be picked more than once during the post-season.",
          "If you have no teams available, you will take a loss for that week.",
          "Three strikes and you're out, including strikes from the regular season.",
          "If more than one person survives the entire season and playoffs, the only tiebreaker is fewer strikes."
      ]
    }
    const websiteRules={
      title:"Getting Your Picks In",
      preText:"The application is here to help you make the right picks",
      rules:[        
          "Picks can be made for any game that has not yet occurred.",
          "Picks can be changed up until midnight (PST) the day before kickoff of the game chosen.  At that time, the pick becomes 'locked' and cannot be changed. ",
          "All picks become visible at the time they are locked.",
          "If a player fails to make a pick, the computer will RANDOMLY assign a pick at the end of the week. That pick cannot be changed.",
          "The app is designed to not allow an illegal pick.  If a glitch occurs, however, it is the entrant's own responsibility to ensure that he or she does not enter improper picks.  If done, a strike will be issued upon discovery.",
      ]
    }

    return (
      <div>
        <PageHeader>OFFICIAL GAME RULES</PageHeader>
        <HelpBlock title={regRules.title} rules={regRules.rules} shortName="regRules" />       
        <HelpBlock title={postSeasonRules.title} rules={postSeasonRules.rules} preText={postSeasonRules.preText} shortName="postSeasonRules" />      
        <HelpBlock title={websiteRules.title} rules={websiteRules.rules} preText={websiteRules.preText} shortName="websiteRules" />                
      </div>
    );
  }
}
