import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./HelpBlock.css";
import { Panel, ListGroup, ListGroupItem } from "react-bootstrap";


class HelpBlock extends Component {
 
  renderRules(rules){
    return [{}].concat(rules).map(
        (rule, i) =>
        i !== 0 ?
            <ListGroupItem key={this.props.shortName+i} className="help-rule">
            {rule}
            </ListGroupItem>
        :""
      )
  }

  renderPreText(preText){
      return(
          <div className="help-preText">{preText}</div>
      );
  }

  render() {
    return(
        <Panel>
            <Panel.Heading>
                <Panel.Title className="help-title">{this.props.title}</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
                {this.props.preText && this.renderPreText(this.props.preText)}
                <ListGroup id={this.props.shortName}>
                    {this.props.rules && this.renderRules(this.props.rules)}
                </ListGroup>
            </Panel.Body>
        </Panel>
      );
  }
}

HelpBlock.propTypes = {
  title: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  preText: PropTypes.string,
  rules: PropTypes.array
}

export default HelpBlock;