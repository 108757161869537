import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import "./Admin.css";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";

import config from "../config";
import LoadingSpinner from "../components/LoadingSpinner";
import LoaderButton from "../components/LoaderButton";
import AlertDismissible from "../components/AlertDismissible";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      alert:false,
      alertText:""
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {

    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>3Strikes</h1>
        <p>You're not supposed to be here...</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  runSchedule() {
    return API.post("3strikes", "/3strikes_admin_importschedule", {body:{ gameId:config.game.GAME_ID, week:0, force:1 } });
  }
  runScheduleSpecificWeek(week) {
    return API.post("3strikes", "/3strikes_admin_importschedule", {body:{ gameId:config.game.GAME_ID, week:week, force:1 } });
  }
  runRunweek(week) {
    return API.post("3strikes", "/3strikes_admin_runweek", {body: {gameId:config.game.GAME_ID, week:week, force:1}});
  }
  runRandomPick() {
    return API.post("3strikes", "/3strikes_admin_randompick", {body:{ force:1 } });
  }
  runRandomPickTest() {
    return API.post("3strikes", "/3strikes_admin_randompick");
  }
  runCleanPicks() {
    return API.post("3strikes", "/3strikes_admin_cleanpicks");
  }
  runHidePicksAllWeeks() {
    return API.post("3strikes", "/3strikes_admin_hidepicks", {body:{ force:1 } });
  }
  runHidePicks() { //post-processing of strikes
    return API.post("3strikes", "/3strikes_admin_hidepicks");
  }
  runReprocessAllStrikes() { // recalc all strikes on all weeks from scratch
    return API.get("3strikes", "/3strikes_admin_updateuserstrikes");
  }
  runGetWinners() {
    return API.post("3strikes", "/3strikes_admin_getwinners", {body: {gameId:config.game.GAME_ID}});
  }
  runGetLogs() {
    return API.get("3strikes", "/3strikes_logs/"+config.game.GAME_ID);
  }
  runGetEmails() {
    return API.get("3strikes", "/3strikes_users/"+config.game.GAME_ID);
  }
  runGetTime() {
    return API.get("3strikes", "/3strikes_admin_gettime");
  }

  processJSONtoString(blob){
    let tString = "";
    blob.forEach(item => {
      tString += JSON.stringify(item);
    });
    return tString;
  }


  validateAdmin(){
      return(
        this.props.gameObj.user.isAdmin
      )
  }

  handleReadSchedule = async event =>{
    this.setState({ isLoading: true });

    if (!this.props.isAuthenticated) {
        return;
      }

    try{

        await this.runSchedule();
        this.setState({alert:true,alertText:"Operation Complete!"});
    }catch(e){
        if(e.message==="Request failed with status code 502"){
            this.setState({alert:true,alertText:"Operation Complete!"});
        }else this.setState({alert:true,alertText:e.message});
    }

    //done
    this.setState({ isLoading: false });
  }

  async handleReadScheduleSpecificWeeks(week){
    this.setState({ isLoading: true });

    if (!this.props.isAuthenticated) {
        return;
      }
    try{
      let result = await this.runScheduleSpecificWeek(week);
      this.setState({alert:true,alertText:"Completed:<br/><br/>"+JSON.stringify(result)});
    }catch(e){
       this.setState({alert:true,alertText:e.message});
    }

    //done
    this.setState({ isLoading: false });
  }

  handleRandomPick = async event =>{
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{

        const picks = await this.runRandomPick();
console.log(picks);
        let message = "No Picks to Make"
        if(picks.length!==0){
          message = "Random picks for week "+picks[0].week+"<ul>";
          picks.forEach(item=>{
              message+="<li><strong>"+item.userName+"</strong> chooses <strong>"+item.selectedWinner+"</strong></li>";
          });
          message+="</ul>";
        }
        message+=JSON.stringify(picks);

        this.setState({alert:true,alertText:message});
    }catch(e){

        //this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  handleRandomPickTest = async event =>{
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{

        const picks = await this.runRandomPickTest();
console.log(picks);
        let message = "No Picks to Make"
        if(picks.length!==0){
          message = "Random picks for week "+picks[0].week+"<ul>";
          picks.forEach(item=>{
              message+="<li><strong>"+item.userName+"</strong> would choose <strong>"+item.selectedWinner+"</strong></li>";
          });
          message+="</ul>";
        }
        message+=JSON.stringify(picks);

        this.setState({alert:true,alertText:message});
    }catch(e){

        //this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  handleCleanPicks = async event =>{
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{
        console.log("Cleaning Active Picks");
        let result = await this.runCleanPicks();
        this.setState({alert:true,alertText:"Completed:<br/><br/>"+JSON.stringify(result)});
    }catch(e){
        this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  handleHidePicksAllWeeks= async event =>{
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{
        console.log("Cleaning Active Picks for ALL WEEKS");
        let result = await this.runHidePicksAllWeeks();
        this.setState({alert:true,alertText:"Completed:<br/><br/>"+JSON.stringify(result, null, 5)});
    }catch(e){
        this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  handleHidePicks = async event =>{
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{
        console.log("Cleaning Strikes");
        let result = await this.runHidePicks();
        this.setState({alert:true,alertText:"Completed:<br/><br/>"+JSON.stringify(result)});
    }catch(e){
        this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  handleReprocessAllStrikes = async event =>{
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{
        console.log("Cleaning ALL Strikes from ALL WEEKS");
        let result = await this.runReprocessAllStrikes();
        this.setState({alert:true,alertText:"Completed:<br/><br/>"+JSON.stringify(result)});
    }catch(e){
        this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  handleGetTime = async event =>{
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{
        console.log("reading datetime from server");
        let result = await this.runGetTime();
        this.setState({alert:true,alertText:"Completed:<br/><br/>"+JSON.stringify(result)});
    }catch(e){
        this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  handleReadWinners = async event =>{
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{
        console.log("reading winners");
        const result = await this.runGetWinners();
        this.setState({alert:true,alertText:"Completed:<br/><br/>"+JSON.stringify(result)});
    }catch(e){
        this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  handleGetLogs = async event=>{
      this.setState({ isLoading: true });
        if (!this.props.isAuthenticated) {
            return;
        }

        try{
            const logs = await this.runGetLogs();
            let message = "<div style=\"height:300px; overflow:auto;\"><table style=\"background-color:#fff; font-size:11px; \" class=\"table table-striped table-hover table-condensed\"><tr><td>Time</td><td>User</td><td>Activity</td><td>Description</td></tr>";
            console.log(logs);
            logs.forEach(log=>{
                let date = (new Date(log.createdAt));
                message+="<tr><td>"+date.toDateString()+" "+date.getHours()+":"+date.getMinutes()+"</td>";
                message+="<td>"+log.name+"</td>";
                message+="<td>"+log.activity+"</td>";
                message+="<td>"+log.description+"</td>";
                message+="</tr>";
            });
            message += "</table></div>";

            this.setState({alert:true,alertText:message});
        }catch(e){
            console.log(e);
            this.setState({alert:true,alertText:e.message});
        }
        this.setState({ isLoading: false });
  }

  handleSendEmail = async event=>{
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{

        const users = await this.runGetEmails();
        let emailList=[];
        users.forEach(element => {
            emailList.push(element.email)
        });
        console.log(emailList);
        let message = (emailList.length)+" emails found for "+(users.length)+" users: <a href=\"mailto:";
        emailList.forEach(email=>{
            message+=email+";";
        });
        message += "\">Send an email to all users</a>";

        this.setState({alert:true,alertText:message});
    }catch(e){
        console.log(e);
        this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  async  handleProcessStrikes(week){
    this.setState({ isLoading: true });
    if (!this.props.isAuthenticated) {
        return;
    }

    try{

        let result = await this.runRunweek(week);
        this.setState({alert:true,alertText:'Running for week: '+week+'<BR><BR>'+JSON.stringify(result)});
    }catch(e){

        //this.setState({alert:true,alertText:e.message});
    }
    this.setState({ isLoading: false });
  }

  renderAdminContent(){
    return (
    <div>
       <div className="row">
        <div>{this.state.alert && <AlertDismissible message={this.state.alertText}/>}</div>
        </div>
        <div className="row adminrow">
            <div className="col-xs-12 sectionTitle">
                User Operations
            </div>
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Send Email"
            loadingText="Running…" alt="Send an email to all active players" onClick={this.handleSendEmail}
            /></div>
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Get Logs"
            loadingText="Running…" onClick={this.handleGetLogs}
            /></div>
        </div>
        <div className="row adminrow">
            <div className="col-xs-12 sectionTitle">
                Game Management Operations
            </div>
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Random Pick Test"
            loadingText="Running…" onClick={this.handleRandomPickTest}
            /></div>
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Random Pick"
            loadingText="Running…" onClick={this.handleRandomPick}
            /></div>

            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Read Winners"
            loadingText="Running…" onClick={this.handleReadWinners}
            /></div>

        </div>
        <div className="row adminrow">
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Process This Week"
            loadingText="Running…" onClick={() => {this.handleProcessStrikes(this.props.gameObj.ActiveWeek)}}
            /></div>
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Process Last Week"
            loadingText="Running…" onClick={() => {this.handleProcessStrikes(this.props.gameObj.ActiveWeek-1)}}
            /></div>

            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Process Exits/Rank"
            loadingText="Running…" onClick={this.handleHidePicks}
            /></div>
        </div>
        <div className="row adminrow">
          <div className="col-xs-12 sectionTitle">
                Game Cleanup Operations
            </div>
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Read Full Schedule"
            loadingText="Running…" onClick={this.handleReadSchedule}
            /></div>
          <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Reprocess ALL Exits"
            loadingText="Running…" onClick={this.handleHidePicksAllWeeks}
            /></div>
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Reprocess All Strikes"
            loadingText="Running…" onClick={() => {this.handleReprocessAllStrikes()}}
            /></div>
             <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text="Sync Picks/Schedule"
            loadingText="Running…" onClick={this.handleCleanPicks}
            /></div>
        </div>
        <div className="row adminrow">
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text={"Read Week ("+this.props.gameObj.ActiveWeek+") Sch."}
            loadingText="Running…" onClick={() => {this.handleReadScheduleSpecificWeeks(this.props.gameObj.ActiveWeek)}}
            /></div>
            <div className="col-xs-12 col-md-3"><LoaderButton block
            bsSize="large" disabled={!this.validateAdmin()}
            type="submit" isLoading={this.state.isLoading} text={"Read Week ("+(this.props.gameObj.ActiveWeek+1)+") Sch."}
            loadingText="Running…" onClick={() => {this.handleReadScheduleSpecificWeeks(this.props.gameObj.ActiveWeek+1)}}
            /></div>
        </div>
    </div>
    );
  }

  renderMain() {
    return (
      <div className="notes z-widget">
        <div className="z-widget-head">
          <span className="title">Admin Options</span></div>
          <div className="z-widget-body">
          <div className="z-widget-loading"><LoadingSpinner isLoading={this.state.isLoading} /></div>
        <ListGroup>
          {!this.state.isLoading && this.renderAdminContent()}
        </ListGroup>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderMain() : this.renderLander()}
      </div>
    );
  }
}
