import React, { Component } from "react";
import "./AdminSchedule.css";
import { API } from "aws-amplify";
import ScheduleList from "../components/ScheduleList";

import LoadingSpinner from "../components/LoadingSpinner";

export default class AdminSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      scheduleData: []
    };
  }

  handleChange(e){
      //this.setState({id:e.value, name:e.label})
      console.log("FILTER TO: "+e.value);

   }

  sortJSONArray(jsonArray, key) {
    return jsonArray.sort(function(a, b) {
      var x = a[key];
      var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
          let games = await this.runGetSchedule();
          games = this.sortJSONArray(games, "gameTime")

          let finalarray = [];
          for(let i=0;i<25;i++){
            finalarray.push({
              "week":i,
              "schedule":[]
            })
          }
          
          games.forEach(game=>{
            //console.log("adding "+game.scheduleId)
            finalarray[parseInt(game.weekNum)].schedule.push(game);
          })

          console.log(finalarray);

        this.setState({scheduleData:finalarray});

    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false,  });
    this.props.loadStandings(false);

  }
  

  runGetSchedule() {
    return API.get("3strikes", "/3strikes_admin_getschedule/");
  }

  //Actual Page Data
  renderContent(){
    return(
      <div>
        <div>
          Schedule
        </div>
        <div style={{ width: "100%", height: "75vh" }}>
          <ScheduleList fullSchedule={this.state.scheduleData} />
        </div>
      </div>
    );
  }

  //Page Frame
  renderMain() {
    return (
      <div className="notes z-widget">
        <div className="z-widget-head">
          <span className="title">Activity Feed</span></div>
          <div className="z-widget-body">
          <div className="z-widget-loading"><LoadingSpinner isLoading={this.state.isLoading} /></div>
          {!this.state.isLoading && this.renderContent()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderMain() : this.renderLander()}
      </div>
    );
  }
}
