import React from "react";
import { Glyphicon } from "react-bootstrap";
import "./LoadingSpinner.css";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <div className={`LoadingSpinner ${className}`}>
    {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
    {!isLoading ? text : loadingText}
  </div>;
