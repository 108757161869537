import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./WeekSelector.css";
import { Button } from "react-bootstrap";


class WeekSelector extends Component {
  /*constructor(props) {
    super(props)

  }*/

  renderButton(week){
   if(this.props.activeWeek===week){
    return(
      <Button className="weekSelector-active active">{week}</Button>
      );
   }else{
    return(
      <Button onClick={() => this.props.handleClick(week)} >{week}</Button>
      );
   }
  }

  renderWeekList(weekList){
    return [{}].concat(weekList).map(
      (week, i) =>
      i !== 0 ?<span>
          {this.renderButton(i)}
          { ((i<this.props.weekList.length + 1)? " | " : "")}
          </span>
      : <span></span>
    )
  }

  render() {
    return(<div className="weekSelector">
        <span className="weekSelector-title">View Week:</span> [ {this.renderWeekList(this.props.weekList)} ]
        </div>);
  }
}

WeekSelector.propTypes = {
  handleClick: PropTypes.func.isRequired,
  weekList: PropTypes.array.isRequired,
  activeWeek: PropTypes.number.isRequired,
}

WeekSelector.defaultProps = {
    weekList: [1],
    activeWeek: 1
};

export default WeekSelector;