import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./ScheduleList.css";
import ScheduleBlock from './ScheduleBlock';

class ScheduleList extends Component {
 
    renderList(weeks){
    return [{}].concat(weeks).map(
        (week, i) =>
        i !== 0 && week.schedule.length >0 ?
            <ScheduleBlock key={week+i} title={"Week "+week.week} games={week.schedule} shortName={"Week"+week.week} collapsed={false} />
        :""
      )
  }

  render() {
    return(
        <div>
            {this.props.fullSchedule && this.renderList(this.props.fullSchedule)}             
            </div>
      );
  }
}

ScheduleList.propTypes = {
  fullSchedule: PropTypes.array
}

export default ScheduleList;