  const dev = {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: "us-west-2",
      BUCKET: "3strikes-app-upload"
    },
    apiGateway: {
      REGION: "us-west-2",
      URL: (!navigator.onLine && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) ? 'http://localhost:4000' : "https://hmelopjzrk.execute-api.us-west-2.amazonaws.com/prod"
      //process.env.IS_OFFLINE ? 'http://localhost:3000' : undefined,
    },
    cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_zSt8G4S5K",
      APP_CLIENT_ID: "6a6onpdfo4u7j5f2tvthhtss2c",
      IDENTITY_POOL_ID: "us-west-2:201d4b93-469f-4283-968b-70c90ac84433"
    },
    social: {
      FB: "2400631610152317"
    },
    game:{
      GAME_ID:"2024"
    }
  };
  
  const prod = {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: "us-west-2",
      BUCKET: "3strikes-app-upload"
    },
    apiGateway: {
      REGION: "us-west-2",
      URL: "https://hmelopjzrk.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_zSt8G4S5K",
      APP_CLIENT_ID: "6a6onpdfo4u7j5f2tvthhtss2c",
      IDENTITY_POOL_ID: "us-west-2:201d4b93-469f-4283-968b-70c90ac84433"
    },
    social: {
      FB: "2400631610152317"
    },
    game:{
      GAME_ID:"2024"
    }
  };
  
  // Default to dev if not set
  // TO BUILD PROD: set "REACT_APP_STAGE=prod" && npm start
  const config = process.env.NODE_ENV === 'production'
    ? prod
    : dev;

  export default config;