import React, { Component } from "react";
import "./Activity.css";
import { API } from "aws-amplify";
import config from "../config";
import Select from 'react-select';

import LoadingSpinner from "../components/LoadingSpinner";
import { Chrono } from "react-chrono";

export default class Activity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: [],
      rawdata: [],
      uniqueNames: []
    };
  }

  handleChange(e){
      //this.setState({id:e.value, name:e.label})
      console.log("FILTER TO: "+e.value);

      //filter data based on selection.
      if(e.value==='ALL'){
        this.setState({data:this.state.rawdata});
      }else{
        let newData = [];
        this.state.rawdata.forEach(log=>{
          if(log.cardTitle===e.value) newData.push(log);
        });
        console.log(newData);
        this.setState({data:newData});
      }
   }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
          const logs = await this.runGetLogs();
          let data = [], namesSet = new Set();
          // uniqueNames = [{value:'ALL',label:'All Users'}];
          //console.log(logs)
          logs.forEach(log=>{
              let dTemp = ( (new Date(log.createdAt)));
              let temp={
                title:dTemp.toDateString()+" "+dTemp.getHours()+":"+dTemp.getMinutes(),
                cardTitle: log.name,
                //cardSubtitle: log.activity,
                //cardDetailedText: log.description
              };
              //formatting activity/description
              if(log.activity==="LoggedIn"){
                temp.cardSubtitle = "Logged In";
                temp.cardDetailedText = "";
              }
              else if(log.activity==="MadePick"){
                // temp.cardSubtitle = log.activity;
                 let tArray = log.description.split(",");
                 let tString = (tArray[0]).substring(1)+" selected "+tArray[1].split(":")[1]+" over "+tArray[2].split(":")[1];
                 temp.cardSubtitle = tString.substring(0,tString.length-1);
              }
              else if(log.activity==="DeletePick"){
                 let tString = "Deleted Pick for "+log.description;
                 temp.cardSubtitle = tString.substring(0,tString.length-1);
              }else{
                temp.cardSubtitle = log.activity;
                temp.cardDetailedText = log.description;
              }


              data.push(temp); // add log
              namesSet.add(log.name);
          });

        let uniqueNames = [{value:'ALL',label:'All Users'}];
        //add user if unique to filter list
        namesSet.forEach(function(item){
          uniqueNames.push({value:item, label:item});
        });
      
        this.setState({data:data, rawdata:data, uniqueNames:uniqueNames});

    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false,  });
    this.props.loadStandings(false);

  }
  

  runGetLogs() {
    return API.get("3strikes", "/3strikes_logs/"+config.game.GAME_ID);
  }

  

  //Actual Page Data
  renderContent(){
    return(
      <div>
        <div>
          <form className="selectForm">
            <Select options={this.state.uniqueNames} onChange={this.handleChange.bind(this)} />
          </form>
        </div>
        <div style={{ width: "100%", height: "75vh" }}>
          <Chrono
            items={this.state.data} 
            mode="VERTICAL"
            allowDynamicUpdate={true}
            cardHeight={15}
            scrollable={{ scrollbar: false }}
            useReadMore={false}
            theme={{ 
              primary: "#2cc185",
              secondary: "#ccc",
              cardBgColor: "#fff",
              cardForeColor: "000",
              titleColor: "#000"
            }}
          />
        </div>
      </div>
    );
  }

  //Page Frame
  renderMain() {
    return (
      <div className="notes z-widget">
        <div className="z-widget-head">
          <span className="title">Activity Feed</span></div>
          <div className="z-widget-body">
          <div className="z-widget-loading"><LoadingSpinner isLoading={this.state.isLoading} /></div>
          {!this.state.isLoading && this.renderContent()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderMain() : this.renderLander()}
      </div>
    );
  }
}
