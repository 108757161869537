 

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NFLHelper from "../components/NFLTeams";
import "./PickItem.css";

class ScheduleBlockItem extends Component {
  render() {
    return(
    <div className="indicator-item">
        <div className="indicator-item-icon">
         <div className={"teamlogo "+NFLHelper.TeamLogoXS(this.props.awayId)}></div>
        </div>
        <div className="indicator-item-value center-text">
          <span className="indicator-value-counter" data-toggle="counter" data-end="36">
            <span className={"badge badge-warning "+(this.props.isProcessed?"":"hidden")}>Winner: {NFLHelper.TeamFullName(this.props.winner)} </span></span>
            <div className="indicator-value-title center-text">
              {  NFLHelper.TeamFullName(this.props.awayId)} at {  NFLHelper.TeamFullName(this.props.homeId)} 
            </div>
        </div>
        <div className="indicator-item-icon">
         <div className={"teamlogo "+NFLHelper.TeamLogoXS(this.props.homeId)}></div>
        </div>
    </div>);
  }
}

ScheduleBlockItem.propTypes = {
  handleClick: PropTypes.func,
  awayId: PropTypes.string.isRequired,
  homeId: PropTypes.string.isRequired,
  scheduleId: PropTypes.string.isRequired,
  gameType: PropTypes.string.isRequired,
  gameTime: PropTypes.number.isRequired,
  weekNum: PropTypes.number.isRequired,
  isProcessed: PropTypes.bool
}

ScheduleBlockItem.defaultProps = {
    disabled: false,
    isProcessed: false
};

export default ScheduleBlockItem;