 

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PickItem from './PickItem';
import { LinkContainer } from "react-router-bootstrap";
import { ListGroupItem, ListGroup } from "react-bootstrap";
import "./PickList.css";

class PickList extends Component {

    renderPickList(picks){
        return [{}].concat(picks).map(
            (pick, i) =>
            i !== 0
                ? <LinkContainer
                    key={i}
                    to={this.props.to}
                >
                    <ListGroupItem key={i} className={(pick.strikes>0)?"strikeBox":""}>
                    <PickItem selectedLoser={pick.selectedLoser} selectedWinner={pick.selectedWinner} pickId={pick.pickId} isRandom={pick.isRandom} />
                    </ListGroupItem>
                </LinkContainer>
                :<span id="nopicks" key="nopicks">{pick.length===0? <span className="z-widget-body-text">"No Picks Yet"</span>:""}</span>
        );
    }

    render() {
       // console.log("PICKS: "+this.props.picks);
        return(
            <ListGroup className="pick-list">
            {this.renderPickList(this.props.picks)}
            </ListGroup>
        );
    }
}

    PickList.propTypes = {
        handleClick: PropTypes.func,
        picks: PropTypes.array.isRequired,
        to: PropTypes.string
    }

    PickList.defaultProps = {
        picks: [],
        to: "/picks"
    };

export default PickList;