import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./ScheduleBlock.css";
import ScheduleBlockItem from './ScheduleBlockItem';
import { Panel, ListGroup, ListGroupItem, Button } from "react-bootstrap";

class ScheduleBlock extends Component {
 
    constructor() {
        super();
        this.state = {
          collapsed: false
        };
      }
      
    CollapseStandings = this.CollapseStandings.bind(this);

    CollapseStandings(event){
        console.log("Hide Click")
        this.setState({collapsed:!this.state.collapsed})
      }

  renderRules(games){
    return [{}].concat(games).map(
        (game, i) =>
        i !== 0 ?
            <ListGroupItem key={this.props.shortName+i} className="help-rule schedule-list">
                <ListGroupItem key={i+"_"+game.scheduleId} className="">
                    <ScheduleBlockItem 
                        awayId={game.awayId} 
                        homeId={game.homeId} 
                        scheduleId={game.scheduleId} 
                        gameType={game.gameType}
                        weekNum={game.weekNum}
                        gameTime={game.gameTime}
                        isProcessed={game.isProcessed}
                        winner={game.winner}
                     /> 
                    </ListGroupItem>
            </ListGroupItem>
        :""
      )
  }

  renderPreText(preText){
      return(
          <div className="help-preText">{preText}</div>
      );
  }

  render() {
    return(
        <Panel>
            <Panel.Heading>
                <Panel.Title className="help-title">{this.props.title}
                <Button className="collapse-btn-alwaysvisible z-mini-btn" onClick={() => { this.CollapseStandings() }}>
                    {!this.state.collapsed ?
                        <i className="fas fa-angle-double-down "></i>
                        : <i className="fas fa-angle-double-up "></i> }
                    </Button>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body>
                {this.props.preText && this.renderPreText(this.props.preText)}
                <ListGroup id={this.props.shortName}>
                    {this.props.games && this.renderRules(this.props.games)}
                </ListGroup>
            </Panel.Body>
        </Panel>
      );
  }
}

ScheduleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  preText: PropTypes.string,
  collapsed: PropTypes.bool,
  games: PropTypes.array
}

export default ScheduleBlock;