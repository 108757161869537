import React, { Component } from "react";
import "./Home.css";
import { API } from "aws-amplify";
import config from "../config";

import { ComposedChart, Bar, Area, XAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import LoadingSpinner from "../components/LoadingSpinner";
import PickList from "../components/PickList";


export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: [],
      strikeChartData:  []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const notes = await this.notes();
      //console.log(notes);
      this.setState({ notes });

      this.setState({strikeChartData:this.props.gameObj.gameChart});
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
    this.props.loadStandings(false);

  }

  notes() {
    return API.get("3strikes", "/3strikes_picks",{
      'queryStringParameters': {
        'gameId': config.game.GAME_ID
      }
    });
  }

  renderNotesList(notes) {
    return(
      <PickList picks={notes} ></PickList>
    );
  }

  renderStrikesChart(){
    let ticks=[1,3,5,7,9,11,13,15,17,19,21,23];
    return (
      <ResponsiveContainer width="100%" height={200}>
       <ComposedChart data={this.state.strikeChartData}
            margin={{top: 0, right: 20, left: 20, bottom: 12}}>
        <XAxis dataKey="week" interval="preserveStartEnd" ticks={ticks}/>
        <Tooltip/>
        <Legend />
          <Area type="monotone" dataKey="Picks" fill="#1db377" stroke="#1db377"  />
          <Area type="monotone" dataKey="Strikes" fill="#f05770"  stroke="#f05770" />
          <Bar type="monotone" dataKey="Exits" fill="#ff0000"  stroke="#ff0000" />
      </ComposedChart>
      </ResponsiveContainer>
    )
  }

  renderLander() {
    return (
      <div>
      <div id="loadingApp" class="loading-centered">
    <div><img width="100" height="100" src="/logo_loading.gif" alt="Loading..." /></div>
    <div class="loadingText">
      LOADING...
  </div>
  </div>
  </div>
    );
  }

  renderUserStats(){
   return(
     <div className="userStats">
       <div className="indicator-item">
          <div className="indicator-item-icon">
            <div className="icon"><i className="fas fa-user"></i></div>
          </div>
          <div className="indicator-item-value"><span className="indicator-value-counter" data-toggle="counter">{this.props.gameObj.user.firstName + " " + this.props.gameObj.user.lastName}</span>
            <div className="indicator-value-title">{this.props.gameObj.user.email}</div>
          </div>
        </div>
        <div className="indicator-item">
          <div className="indicator-item-icon">
            <div className="icon"><i className="fas fa-clipboard-check"></i></div>
          </div>
          <div className="indicator-item-value"><span className="indicator-value-counter" data-toggle="counter" data-end={this.props.gameObj.user.strikes}>{this.props.gameObj.user.strikes}</span>
            <div className="indicator-value-title">Strike{this.props.gameObj.user.strikes===1?"":"s"}</div>
          </div>
        </div>
        <div className="indicator-item">
          <div className="indicator-item-icon">
            <div className="icon"><i className="fas fa-award"></i></div>
          </div>
          <div className="indicator-item-value"><span className="indicator-value-counter" data-toggle="counter">{this.props.gameObj.activePlayers}/{this.props.gameObj.standings.length}</span>
            <div className="indicator-value-title">Players Remaining</div>
          </div>
        </div>
    </div>);
  }


/*
<div className="z-widget">
              <div className="z-widget-head">
                <span className="title">Pick Stats</span></div>
              <div className="">
              <div className="z-widget-loading"></div>
              {!this.state.isloading && this.renderStrikesChart()}
              </div>
          </div>*/

  renderNotes() {
    return (
      <div className="row">
        <div className="col-md-5">
          <div className="z-widget">
              <div className="">
              <div className="z-widget-loading"></div>
              {!this.state.isloading && this.renderUserStats()}
              </div>
          </div>
          <div className="z-widget">
              <div className="z-widget-head-compact">
                <span className="title">Pick Stats by Week</span></div>

              {!this.state.isloading && this.props.isAuthenticated && this.renderStrikesChart()}

          </div>
        </div>
        <div className="col-md-7">
          <div className="notes z-widget">
            <div className="z-widget-head">
              <span className="title">Your Picks</span></div>
              <div className="z-widget-body">
              <div className="z-widget-loading"><LoadingSpinner isLoading={this.state.isLoading} /></div>

              {!this.state.isLoading && this.props.isAuthenticated && this.renderNotesList(this.state.notes)}

            </div>
          </div>
        </div>
      </div>

    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated && this.props.gameObj.user!==undefined ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}
